<template>
  <div class="login-screen-bg">
    <div class="box-login elevation-15 pa-10">
      <v-col
        cols="12"
        class="d-flex flex-column justify-center"
      >
        <div class="d-flex flex-column justify-center align-center mb-5">
          <v-img
            lazy-src="../../assets/logo/anikaclinic-logo.png"
            max-height="120"
            max-width="120"
            alt="Anika Clinic Logo"
            contain
            src="../../assets/logo/anikaclinic-logo.png"
          ></v-img>
        </div>
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          name="input-10-2"
          label="อีเมล"
          hint="กรุณากรอกอีเมล"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          name="input-10-2"
          label="รหัสผ่าน"
          hint="กรุณากรอกรหัสผ่าน"
          @click:append="showPassword = !showPassword"
          outlined
        ></v-text-field>
        <div class="d-flex flex-column justify-center align-center">
          <v-btn
            class="btn-login px-12 py-5"
            :loading="loading"
            :disabled="loading"
            color="secondary"
            @click="login()"
          >
            เข้าสู่ระบบ
          </v-btn>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      title: 'Preliminary report',
      email: '',
      password: '',
      showPassword: false,
      loader: null,
      loading: false,
      rules: {
        required: value => !!value || 'จำเป็น',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'อีเมลไม่ถูกต้อง'
        }
      },
      users: {}
    }
  },
  created () {
    this.$store.dispatch('logoutUser')
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => (this[l] = false), 3000)
      this.loader = null
    }
  },
  methods: {
    async login () {
      const self = this
      // self.loading = true
      const obj = {
        username: self.email,
        password: self.password
      }
      if (self.email && self.password) {
        // self.loader = 'loading'
        self.loading = true
        try {
          const res = await self.axios.post(process.env.VUE_APP_API + '/login', obj)
          if (res.status === 200) {
            self.users = res.data.result
            const userData = {
              email: res.data.result.email,
              clinic: res.data.result.clinic,
              branch: res.data.result.branch,
              token: `Bearer ${res.data.result.access_token}`
            }
            self.$store.dispatch('loginUser', userData)
          } else {
            swal('ผิดพลาด', 'ไม่พบผู้ใช้งานนี้ในระบบ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            }).then(() => {
              // self.loader = null
              self.loading = false
            })
          }
        } catch (error) {
          console.log(error.message)
        } finally {
          if (self.$store.state.isLogged) {
            swal('สำเร็จ', 'เข้าสู่ระบบสำเร็จ', 'success', {
              button: false,
              timer: 2000
            }).then(() => {
              self.$router.push({ path: 'dashboard/register' })
              // self.$router.push({ path: 'dashboard' })
              // self.loader = null
              self.loading = false
            })
          } else {
            swal('ผิดพลาด', 'ไม่สามารถเข้าสู่ระบบได้ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            }).then(() => {
              // self.loader = null
              self.loading = false
            })
          }
        }
      } else {
        swal('คำเตือน', 'กรุณากรอกอีเมล/รหัสผ่าน', 'warning', {
          button: false,
          timer: 3000
        }).then(() => {
          // self.loader = null
          self.loading = false
        })
      }
    }
  }
}
</script>

<style>

</style>
