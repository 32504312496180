import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogged: false,
    user: {},
    token: ''
  },
  mutations: {
    LOGIN (state, payload) {
      state.isLogged = true
      state.user = payload
      state.token = payload.token
    },
    LOGOUT (state) {
      state.isLogged = false
      state.user = {}
      state.token = ''
    }
  },
  actions: {
    loginUser ({ commit }, payload) {
      localStorage.setItem('isLogged', true)
      localStorage.setItem('Token', payload.token)
      commit('LOGIN', payload)
    },
    logoutUser ({ commit }) {
      localStorage.setItem('isLogged', false)
      localStorage.setItem('Token', '')
      commit('LOGOUT')
    }
  },
  modules: {
  }
})
