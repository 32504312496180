import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from '../constants/colors'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.lightblue,
        darkyellow: colors.darkyellow,
        yellow: colors.yellow,
        gray: colors.gray,
        darkestgray: colors.darkestgray
      },
      dark: {
        primary: colors.lightblue,
        darkyellow: colors.darkyellow,
        yellow: colors.yellow,
        gray: colors.gray,
        darkestgray: colors.darkestgray
      }
    }
  }
})
