<template>
  <v-app id="navbar">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="drawer"
      permanent
      app
    >
      <v-sheet
        color="white accent-4"
        class="pa-4 d-flex flex-column justify-center align-center"
      >
        <v-avatar
          v-if="drawer"
          color="grey darken-1"
          size="36"
        >
          <v-img
            lazy-src="../../assets/logo/anikaclinic-logo.png"
            alt="Anika Clinic Logo"
            contain
            src="../../assets/logo/anikaclinic-logo.png"
          ></v-img>
        </v-avatar>

        <div
          v-else
          class="d-flex flex-row justify-center align-center"
        >
        <v-avatar
          color="grey darken-1"
          size="36"
          class="mr-3"
        >
          <v-img
            lazy-src="../../assets/logo/anikaclinic-logo.png"
            alt="Anika Clinic Logo"
            contain
            src="../../assets/logo/anikaclinic-logo.png"
          ></v-img>
        </v-avatar>
        <span class="title-navbarb">Anika Clinic (Backoffice)</span>
        </div>

      </v-sheet>

      <v-list color="">
        <!-- <v-list-item
          v-for="[icon, text, link, subitems] in links"
          :key="icon"
          link
        >
          <template>
            <v-list-item-icon>
              <v-icon color="#7b8190">{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([text, link], i) in subitems"
            :key="i"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item> -->
        <v-list-group
          v-for="item in links"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          :append-icon="item.items ? 'mdi-chevron-down' : ''"
          active-class="title-all-page-link-active"
        >
          <template v-slot:activator>
            <v-list-item-content @click="gotolink(item.name)" class="title-all-page-link">
              <v-list-item-title v-text="item.title" :class="item.active && ('title-all-page-link-active')"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            v-model="child.active"
            link
          >
            <v-list-item-content @click="gotolink(child.name)" class="title-all-page-link">
              <v-list-item-title v-text="child.title" :class="item.active && ('title-all-page-link-active')"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item link @click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ออกจากระบบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      color="white accent-4"
      light
      app
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" color="#555b6d"></v-app-bar-nav-icon>

      <!-- <v-toolbar-title style="color: #E5BC49">Anika Clinic (Backoffice)</v-toolbar-title> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-overlay :value="overlay">
      <fulfilling-square-spinner
        :animation-duration="4000"
        :size="90"
        color="#5b73e8"
      />
    </v-overlay>
  </v-app>
</template>

<script>
import { FulfillingSquareSpinner } from 'epic-spinners'

export default {
  components: { FulfillingSquareSpinner },
  data: () => ({
    cards: ['Today', 'Yesterday'],
    drawer: null,
    overlay: false,
    links: [{
      action: 'mdi-home-outline',
      title: 'Dashboard',
      link: 'dashboard',
      name: 'DashboardScreen',
      items: [{
        title: 'Dashboard Register',
        link: 'dashboard/register',
        name: 'DashboardRegisterScreen'
      }, {
        title: 'Dashboard Doctor',
        link: 'dashboard/today',
        name: 'DashboardDoctorAndAssistantScreen'
      }]
    }, {
      action: 'mdi-calendar-check',
      title: 'งานวันนี้',
      link: 'work/today',
      name: 'WorkTodayScreen',
      items: null
      // items: [{
      //   title: 'งานเมื่อถึงคลินิก',
      //   link: 'work/arrive',
      //   name: 'WorkArriveScreen'
      // }, {
      //   title: 'งานเมื่อจบวัน',
      //   link: 'work/finish',
      //   name: 'WorkFinishScreen'
      // }]
    }, {
      action: 'mdi-chart-line',
      items: null,
      title: 'รายงาน',
      link: ''
    }, {
      action: 'mdi-wallet-outline',
      title: 'การเงิน',
      name: 'AccountSummaryScreen',
      items: [{
        title: 'สรุปบัญชี',
        link: 'finance/summary',
        name: 'AccountSummaryScreen'
      }, {
        title: 'บันทึกค่าใช้จ่าย',
        link: 'finance/outcome',
        name: 'OutcomeAccountScreen'
      }, {
        title: 'กระเป๋าเงิน',
        link: 'finance/wallet',
        name: 'WalletAccountScreen'
      }, {
        title: 'บัญชีลูกหนี้',
        link: 'finance/receivable',
        name: 'ReceivableAccountScreen'
      }]
    }, {
      action: 'mdi-cash-register',
      title: 'ใบเสร็จ',
      name: 'AllBillScreen',
      items: [{
        title: 'รายการใบเสร็จ',
        link: 'bill/all',
        name: 'AllBillScreen'
      }, {
        title: 'สร้างใบเสร็จ',
        link: 'bill/create',
        name: 'CreateBillScreen'
      }]
    }, {
      action: 'mdi-account-box-outline',
      items: null,
      title: 'จัดการสิทธิ์ผู้ใช้',
      link: ''
    }, {
      action: 'mdi-storefront-outline',
      items: null,
      title: 'จัดการข้อมูลคลินิค',
      link: ''
    }, {
      action: 'mdi-clipboard-text-outline',
      items: null,
      title: 'คิวบริการวันนี้',
      link: 'queue/today',
      name: 'ServiceQueueTodayScreen'
    }, {
      action: 'mdi-cash',
      items: null,
      title: 'ชำระเงิน',
      link: ''
    }, {
      action: 'mdi-cup-outline',
      items: null,
      title: 'ฉลากยา',
      link: ''
    }, {
      action: 'mdi-hospital-box-outline',
      items: null,
      title: 'ใบรับรองแพทย์',
      link: ''
    }, {
      action: 'mdi-account-multiple-outline',
      title: 'ข้อมูลลูกค้า',
      link: 'customer/all',
      name: 'AllCustomerOverviewScreen',
      items: [{
        title: 'ข้อมูลลูกค้าทั้งหมด',
        link: 'customer/all',
        name: 'AllCustomerOverviewScreen'
      }]
    }, {
      action: 'mdi-clipboard-text-outline',
      title: 'ประวัติการใช้บริการ',
      link: '',
      name: 'ServiceItemsScreen',
      items: [{
        title: 'รายการที่ใช้บริการ',
        link: 'service/items',
        name: 'ServiceItemsScreen'
      }, {
        title: 'ตรวจสอบบริการ',
        link: 'service/check',
        name: 'CheckServiceScreen'
      }]
    }, {
      action: 'mdi-calendar-blank-outline',
      title: 'ปฏิทินนัดหมาย',
      link: 'appointment',
      name: 'CalendarScreen',
      items: [{
        title: 'ปฏิทิน',
        link: 'appointment/calendar',
        name: 'CalendarScreen'
      }, {
        title: 'โทรนัดเคสก่อนวันนัด',
        link: 'appointment/call',
        name: 'CallDateScreen'
      }]
    }, {
      action: 'mdi-clock-time-nine-outline',
      items: null,
      title: 'จัดการคิวล่วงหน้า',
      link: 'queue/manage',
      name: 'ManageServiceQueueScreen'
    }, {
      action: 'mdi-needle',
      title: 'จัดการหัตถการ / คอร์ส',
      link: 'operativeandcourse',
      name: 'AllListOperativeAndCourseScreen',
      items: [{
        title: 'หมวดหมู่หัตถการ/คอร์ส',
        link: 'operativeandcourse/category/all',
        name: 'AllCategoryOperativeAndCourseScreen'
      }, {
        title: 'รายการหัตถการ/คอร์ส',
        link: 'operativeandcourse/list/all',
        name: 'AllListOperativeAndCourseScreen'
      }]
    }, {
      action: 'mdi-tag-outline',
      items: null,
      title: 'โปรโมชั่น',
      link: ''
    }, {
      action: 'mdi-email-outline',
      items: null,
      title: 'ส่งเสริมการขาย',
      link: ''
    }, {
      action: 'mdi-pill',
      title: 'จัดการยาและเวชภัณฑ์',
      link: 'managedrugsandmedicalsupplies/all',
      name: 'AllDrugsAndMedicalScreen',
      items: [{
        title: 'รายการยาและเวชภัณฑ์',
        link: 'managedrugsandmedicalsupplies/all',
        name: 'AllDrugsAndMedicalScreen'
      }, {
        title: 'กลุ่มยาและเวชภัณฑ์',
        link: 'managedrugsandmedicalsupplies/groupdrugsandmedicalsupplies',
        name: 'GroupDrugsAndMedicalScreen'
      }, {
        title: 'ประเภทยาและเวชภัณฑ์',
        link: 'managedrugsandmedicalsupplies/categorydrugsandmedicalsupplies',
        name: 'CategoryDrugsAndMedicalScreen'
      }, {
        title: 'ยี่ห้อยาและเวชภัณฑ์',
        link: 'managedrugsandmedicalsupplies/branddrugsandmedicalsupplies',
        name: 'BrandDrugsAndMedicalScreen'
      }, {
        title: 'ผู้จำหน่ายยาและเวชภัณฑ์',
        link: 'managedrugsandmedicalsupplies/dealerdrugsandmedicalsupplies',
        name: 'DealerDrugsAndMedicalScreen'
      }, {
        title: 'รอบสต๊อกยาและเวชภัณฑ์',
        link: 'managedrugsandmedicalsupplies/stockcycledrugsandmedicalsupplies',
        name: 'StockCycleDrugsAndMedicalScreen'
      }, {
        title: 'รายการปรับปรุงสต๊อก',
        link: 'managedrugsandmedicalsupplies/stockadjust/all',
        name: 'AllStockAdjustScreen'
      }, {
        title: 'คลังยาและเวชภัณฑ์',
        link: 'managedrugsandmedicalsupplies/warehouse/all',
        name: 'AllDrugAndMedicalWarehouseScreen'
      }]
    }, {
      action: 'mdi-bed-outline',
      items: null,
      title: 'ห้องตรวจ',
      link: 'examinationroom/list',
      name: 'ListExaminationRoomScreen'
    }, {
      action: 'mdi-cash-multiple',
      title: 'จัดการค่ามือ',
      link: 'managewages/doctor/all',
      name: 'AllDoctorWageScreen',
      items: [{
        title: 'ค่ามือแพทย์',
        link: 'managewages/doctor/all',
        name: 'AllDoctorWageScreen'
      }, {
        title: 'ค่ามือผู้ช่วยแพทย์',
        link: 'managewages/assistant/all',
        name: 'AllAssistantWageScreen'
      }]
    }
      // ['mdi-clipboard-text-outline', 'คิวบริการวันนี้', ''],
      // ['mdi-cash', 'ชำระเงิน', ''],
      // ['mdi-cup-outline', 'ฉลากยา', ''],
      // ['mdi-hospital-box-outline', 'ใบรับรองแพทย์', ''],
      // ['mdi-account-multiple-outline', 'ข้อมูลลูกค้า', ''],
      // ['mdi-clipboard-text-outline', 'ประวัติการใช้บริการ', ''],
      // ['mdi-calendar-blank-outline', 'ปฏิทินนัดหมาย', ''],
      // ['mdi-clock-time-nine-outline', 'จัดการคิวล่วงหน้า', ''],
      // ['mdi-needle', 'จัดการหัตถการ / คอร์ส', ''],
      // ['mdi-account-circle', 'ลูกค้า', ''],
      // ['mdi-printer', 'ออกไบเสร็จ', ''],
      // ['mdi-alarm', 'จัดการคิว', ''],
      // ['mdi-medical-bag', 'ห้องตรวจ', ''],
      // ['mdi-card-account-details', 'การใช้บริการ', ''],
      // ['mdi-calendar-range', 'ปฏิทินนัดหมาย', ''],
      // ['mdi-auto-fix', 'จัดการหัตถการ/คอร์ส', ''],
      // ['mdi-needle', 'จัดการคลังยา/อุปกรณ์', ''],
      // ['mdi-email', 'ส่งเสริมการขาย', ''],
      // ['mdi-car-emergency', 'โอนยา/อุปกรณ์', ''],
      // ['mdi-format-list-checks', 'ฉลากยา/ใบรับรองแพทย์', ''],
      // ['mdi-bullhorn', 'โปรโมชัน', ''],
      // ['', 'รายงาน', ''],
      // ['mdi-power', 'ออกจากระบบ', '']
    ]
  }),
  watch: {
    overlay (val) {
      val && setTimeout(() => {
        this.overlay = false
      }, 3000)
    }
  },
  methods: {
    gotolink (link) {
      const self = this
      self.$router.push({ name: link })
    },
    signOut () {
      const self = this
      self.overlay = !this.overlay
      self.$store.dispatch('logoutUser')
      if (!self.$store.state.isLogged) {
        setTimeout(() => {
          self.$router.push({ name: 'LoginScreen' })
          self.overlay = !this.overlay
        }, 3000)
      }
    }
  }
}
</script>
