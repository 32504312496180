import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'
import { LoginScreen } from '../views/index'
import { Appbar } from '../components/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: LoginScreen,
    redirect: () => {
      return '/login'
    }
  },
  {
    path: '/',
    component: LoginScreen,
    redirect: () => {
      // if (store?.state?.isLogged) {
      //   return '/dashboard'
      // } else {
      return '/login'
      // }
    }
  },
  {
    path: '/login',
    name: 'LoginScreen',
    component: LoginScreen
  },
  {
    path: '/',
    name: 'Appbar',
    component: Appbar,
    children: [{
      path: 'dashboard',
      name: 'DashboardScreen',
      // component: () => import('../views/Dashboard/DashboardScreen.vue'),
      component: () => import('../views/Customer/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'register',
        name: 'DashboardRegisterScreen',
        component: () => import('../views/Dashboard/DashboardScreen.vue')
      }, {
        path: 'today',
        name: 'DashboardDoctorAndAssistantScreen',
        component: () => import('../views/Dashboard/DashboardDoctorAndAssistantScreen.vue')
      }]
    }, {
      path: 'work',
      name: 'WorkScreen',
      // component: () => import('../views/Dashboard/DashboardScreen.vue'),
      component: () => import('../views/Work/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'today',
        name: 'WorkTodayScreen',
        component: () => import('../views/Work/Today/_All.vue')
      }, {
        path: 'arrive',
        name: 'WorkArriveScreen',
        component: () => import('../views/Work/Today/_Arrive.vue')
      }, {
        path: 'finish',
        name: 'WorkFinishScreen',
        component: () => import('../views/Work/Today/_Finish.vue')
      }]
    }, {
      path: 'finance',
      name: 'FinanceScreen',
      component: () => import('../views/Finance/index.vue'),
      children: [{
        path: 'summary',
        name: 'AccountSummaryScreen',
        component: () => import('../views/Finance/AccountSummary/_Summary.vue')
      }, {
        path: 'outcome',
        name: 'OutcomeAccountScreen',
        component: () => import('../views/Finance/Outcome/_All.vue')
      }, {
        path: 'wallet',
        name: 'WalletAccountScreen',
        component: () => import('../views/Finance/Wallet/_All.vue')
      }, {
        path: 'receivable',
        name: 'ReceivableAccountScreen',
        component: () => import('../views/Finance/AccountsReceivable/_All.vue')
      }]
    }, {
      path: 'bill',
      name: 'BillScreen',
      component: () => import('../views/Bill/index.vue'),
      children: [{
        path: 'all',
        name: 'AllBillScreen',
        component: () => import('../views/Bill/_All.vue')
      }, {
        path: 'create',
        name: 'CreateBillScreen',
        component: () => import('../views/Bill/_Create.vue')
      }, {
        path: 'pay',
        name: 'PayListScreen',
        component: () => import('../views/Bill/index.vue'),
        children: [{
          path: 'list',
          name: 'ListPayScreen',
          component: () => import('../views/Bill/PayList/_List.vue')
        }, {
          path: 'print',
          name: 'PrintPayScreen',
          component: () => import('../views/Bill/PayList/_Print.vue')
        }]
      }]
    }, {
      path: 'queue',
      name: 'ServiceQueueScreen',
      component: () => import('../views/ServiceQueue/index.vue'),
      children: [{
        path: 'today',
        name: 'ServiceQueueTodayScreen',
        component: () => import('../views/ServiceQueue/Today/_All.vue')
      }, {
        path: 'manage',
        name: 'ManageServiceQueueScreen',
        component: () => import('../views/ServiceQueue/Manage/_All.vue')
      }]
    }, {
      path: 'customer',
      name: 'CustomerScreen',
      component: () => import('../views/Customer/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'all',
        name: 'AllCustomerOverviewScreen',
        component: () => import('../views/Customer/Overview/_All.vue')
      }, {
        path: 'create',
        name: 'CustomerCreateScreen',
        component: () => import('../views/Customer/Overview/_Create.vue')
      }, {
        path: 'view',
        name: 'CustomerViewScreen',
        component: () => import('../views/Customer/Overview/_View.vue')
      }, {
        path: 'visit',
        name: 'VisitOfCustomerScreen',
        component: () => import('../views/Customer/index.vue'),
        children: [{
          path: 'create',
          name: 'VisitOfCustomerCreateScreen',
          component: () => import('../views/Customer/Visit/_Create.vue')
        }]
      }]
    }, {
      path: 'service',
      name: 'ServiceScreen',
      component: () => import('../views/Service/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'items',
        name: 'ServiceItemsScreen',
        component: () => import('../views/Service/ServiceItems/_All.vue')
      }, {
        path: 'check',
        name: 'CheckServiceScreen',
        component: () => import('../views/Service/CheckService/_All.vue')
      }]
    }, {
      path: 'appointment',
      name: 'AppointmentScreen',
      component: () => import('../views/Appointment/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'calendar',
        name: 'CalendarScreen',
        component: () => import('../views/Appointment/Calendar/_All.vue')
      }, {
        path: 'call',
        name: 'CallDateScreen',
        component: () => import('../views/Appointment/CallForCaseBeforeDate/_All.vue')
      }]
    }, {
      path: 'operativeandcourse',
      name: 'OperativeAndCourseScreen',
      component: () => import('../views/OperativeAndCourse/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'category',
        name: 'CategoryOperativeAndCourseScreen',
        component: () => import('../views/OperativeAndCourse/index.vue'),
        children: [{
          path: 'all',
          name: 'AllCategoryOperativeAndCourseScreen',
          component: () => import('../views/OperativeAndCourse/Category/_All.vue')
        }]
      }, {
        path: 'list',
        name: 'ListOperativeAndCourseScreen',
        component: () => import('../views/OperativeAndCourse/index.vue'),
        children: [{
          path: 'all',
          name: 'AllListOperativeAndCourseScreen',
          component: () => import('../views/OperativeAndCourse/List/_All.vue')
        }, {
          path: 'create',
          name: 'CreateListOperativeAndCourseScreen',
          component: () => import('../views/OperativeAndCourse/List/_Create.vue')
        }, {
          path: 'create/adddetail',
          name: 'CreateAddDetailListOperativeAndCourseScreen',
          component: () => import('../views/OperativeAndCourse/List/_AddDetail.vue')
        }, {
          path: 'update',
          name: 'UpdateListOperativeAndCourseScreen',
          component: () => import('../views/OperativeAndCourse/List/_Update.vue')
        }]
      }]
    }, {
      path: 'managedrugsandmedicalsupplies',
      name: 'ManageDrugsAndMedicalScreen',
      component: () => import('../views/ManageDrugsAndMedical/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'all',
        name: 'AllDrugsAndMedicalScreen',
        component: () => import('../views/ManageDrugsAndMedical/ListDrugsAndMedical/_All.vue')
      }, {
        path: 'description',
        name: 'DrugsAndMedicalDescriptionScreen',
        component: () => import('../views/ManageDrugsAndMedical/ListDrugsAndMedical/_Description.vue')
      }, {
        path: 'update',
        name: 'DrugsAndMedicalUpdateScreen',
        component: () => import('../views/ManageDrugsAndMedical/ListDrugsAndMedical/_Update.vue')
      }, {
        path: 'create',
        name: 'DrugsAndMedicalCreateScreen',
        component: () => import('../views/ManageDrugsAndMedical/ListDrugsAndMedical/_Create.vue')
      }, {
        path: 'addtostock',
        name: 'DrugsAndMedicalAddToStockScreen',
        component: () => import('../views/ManageDrugsAndMedical/AddStock/_AddToStock.vue')
      }, {
        path: 'addtostockagain',
        name: 'DrugsAndMedicalAddToStockAgainScreen',
        component: () => import('../views/ManageDrugsAndMedical/AddStock/_AddToStockAgain.vue')
      }, {
        path: 'groupdrugsandmedicalsupplies',
        name: 'GroupDrugsAndMedicalScreen',
        component: () => import('../views/ManageDrugsAndMedical/GroupDrugs/_All.vue')
      }, {
        path: 'categorydrugsandmedicalsupplies',
        name: 'CategoryDrugsAndMedicalScreen',
        component: () => import('../views/ManageDrugsAndMedical/CategoryDrugs/_All.vue')
      }, {
        path: 'branddrugsandmedicalsupplies',
        name: 'BrandDrugsAndMedicalScreen',
        component: () => import('../views/ManageDrugsAndMedical/BrandDrugs/_All.vue')
      }, {
        path: 'dealerdrugsandmedicalsupplies',
        name: 'DealerDrugsAndMedicalScreen',
        component: () => import('../views/ManageDrugsAndMedical/Dealer/_All.vue')
      }, {
        path: 'stockcycledrugsandmedicalsupplies',
        name: 'StockCycleDrugsAndMedicalScreen',
        component: () => import('../views/ManageDrugsAndMedical/StockCycle/_All.vue')
      }, {
        path: 'stockadjust',
        name: 'StockAdjustScreen',
        component: () => import('../views/ManageDrugsAndMedical/index.vue'),
        children: [{
          path: 'all',
          name: 'AllStockAdjustScreen',
          component: () => import('../views/ManageDrugsAndMedical/StockAdjust/_All.vue')
        }, {
          path: 'create',
          name: 'CreateStockAdjustScreen',
          component: () => import('../views/ManageDrugsAndMedical/StockAdjust/_Create.vue')
        }, {
          path: 'detail',
          name: 'DetailStockAdjustScreen',
          component: () => import('../views/ManageDrugsAndMedical/StockAdjust/_Detail.vue')
        }]
      }, {
        path: 'warehouse',
        name: 'DrugAndMedicalWarehouseScreen',
        component: () => import('../views/ManageDrugsAndMedical/index.vue'),
        children: [{
          path: 'all',
          name: 'AllDrugAndMedicalWarehouseScreen',
          component: () => import('../views/ManageDrugsAndMedical/DrugAndMedicalWarehouse/_All.vue')
        }, {
          path: 'overview',
          name: 'OverviewDrugAndMedicalWarehouseScreen',
          component: () => import('../views/ManageDrugsAndMedical/DrugAndMedicalWarehouse/_Overview.vue')
        }]
      }]
    }, {
      path: 'managewages',
      name: 'AllWageScreen',
      component: () => import('../views/Wages/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'doctor/all',
        name: 'AllDoctorWageScreen',
        component: () => import('../views/Wages/Doctor/Overview/_All.vue')
      }, {
        path: 'assistant/all',
        name: 'AllAssistantWageScreen',
        component: () => import('../views/Wages/Assistant/Overview/_All.vue')
      }]
    }, {
      path: 'examinationroom',
      name: 'ExaminationRoomScreen',
      component: () => import('../views/ExaminationRoom/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'list',
        name: 'ListExaminationRoomScreen',
        component: () => import('../views/ExaminationRoom/List/_All.vue')
      }, {
        path: 'service',
        name: 'ServiceExaminationRoomScreen',
        component: () => import('../views/ExaminationRoom/Service/_Service.vue')
      }]
    }, {
      path: 'doctor',
      name: 'DoctorScreen',
      component: () => import('../views/Doctor/index.vue'),
      meta: { requiresAuth: true },
      children: [{
        path: 'casedetail',
        name: 'CaseDetailScreen',
        component: () => import('../views/Doctor/CaseDetail/_Detail.vue')
      }]
    }]
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!(['/login'].includes(to.path))) {
    if (localStorage.getItem('isLogged') === 'true' && store.state.isLogged) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
